<template>
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <template v-slot:content>
    </template>
    <template v-slot:extraContent>
    </template>
    <template v-slot:extra>
      <a-space>
        <a-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="submitForm">
          发布
        </a-button>
        <a-button type="dashed" @click="back">
          取消
        </a-button>
      </a-space>
    </template>
    <a-card :bordered="false">
      <a-row :gutter="12">
        <a-col :span="24">
          <a-form-model ref="form" :model="form" :rules="rules" :wrapper-col="wrapperCol">
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="公告类型" prop="noticeType">
                  <a-select placeholder="请选择" v-model="form.noticeType" style="width: 200px;">
                    <a-select-option v-for="(d, index) in dict.type['sys_notice_type']" :key="index" :value="d.value">{{
                      d.label }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="状态" prop="status">
                  <a-radio-group v-model="form.bstatus" button-style="solid" @change="changeStatus">
                    <a-radio-button v-for="(d, index) in dict.type['sys_notice_status']" :key="index" :value="d.value">{{
                      d.label }}</a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item label="标题" prop="noticeTitle">
              <a-input size="large" v-model="form.noticeTitle" placeholder="请输入标题" />
            </a-form-model-item>
            <a-form-model-item label="简介" prop="noticeInfo">
              <a-input size="large" v-model="form.noticeInfo" placeholder="请输入简介" />
            </a-form-model-item>
            <a-form-model-item label="公告图片" prop="noticeImg">
              <a-upload
                name="noticeImg"
                listType="picture-card"
                class="avatar-uploader"
                :multiple="false"
                :show-upload-list="false"
                :preview="handlePreview"
                :before-upload="beforeOssUpload"
                :customRequest="coverImgUpload">
                <img
                  v-if="form.noticeImg"
                  :src="form.noticeImg"
                  alt="noticeImg"
                  style="height: 102px; width: 102px;"/>
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'"/>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>

            <a-form-model-item label="内容" prop="noticeContent">
              <editor v-model="form.noticeContent" placeholder="请输入内容" />
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </a-card>
    <!-- <a-drawer width="30%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="公告类型" prop="noticeType">
          <a-select placeholder="请选择" v-model="form.noticeType">
            <a-select-option v-for="(d, index) in dict.type['sys_notice_type']" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status">
          <a-radio-group v-model="form.bstatus" button-style="solid" @change="changeStatus">
            <a-radio-button v-for="(d, index) in dict.type['sys_notice_status']" :key="index" :value="d.value" >{{ d.label }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              发布
            </a-button>
            <a-button type="dashed" @click="onClose">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer> -->
  </page-header-wrapper>
</template>

<script>

import { getNotice, addNotice, updateNotice } from '@/api/system/notice'
import Editor from '@/components/Editor'
import bus from '@/utils/bus'
import {fileUpload} from "@/api/tool/common";
import {generateFilePath} from "@/api/tool/alioss";
export default {
  name: 'NoticeForm',
  components: {
    Editor
  },
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      submitLoading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      // 上传图片
      // open: false,
      previewVisible: false,
      previewImage: '',
      loading: null,
      // 表单参数
      form: {
        noticeId: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeInfo: null,
        noticeImg: null,
        noticeContent: '',
        status: '0',
        bstatus: '1'
      },
      rules: {
        noticeType: [{ required: true, message: '公告类型不能为空', trigger: 'change' }],
        noticeTitle: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        noticeContent: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      open: false,
      isLoading: true
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.params.id
    this.formTitle = this.$route.params.formTitle
    if (this.id) {
      this.handleUpdate(this.id)
    } else {
      this.handleAdd()
    }
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  },
  methods: {
    changeStatus(e) {
      let status = e.target.value
      this.form.status = status === '0' ? '1' : '0'
    },
    // 表单重置
    reset() {
      this.form = {
        noticeId: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: '0',
        bstatus: '1'
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formTitle = '新增公告'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then(response => {
        let str = response.data.status === '0' ? '1' : response.data.status === '1' ? '0' : '0'
        this.$set(response.data, 'bstatus', str)
        this.form = response.data
        this.formTitle = '公告编辑'
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'notice'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.noticeImg = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.submitLoading = true
          if (this.form.noticeId !== undefined) {
            updateNotice(this.form).then(response => {
              this.$message.success(
                '编辑成功',
                3
              )
              // 去更新列表页的数据
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNotice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    back() {
      this.$router.push('/system/notice')
      // this.$router.go(0)
    },
    onClose() {
      this.open = false
    },
    handleSubmit() {
      this.$refs.baseForm.validate(valid => {
        if (valid) {
          this.open = true
        } else {
          return false
        }
      })
    }
  }
}
</script>
